import { UploadService } from 'projects/shared/services/upload.service';
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})

export class FileUploaderComponent implements OnInit, OnDestroy {
  @Input() control: FormControl = new FormControl();

  subscription: Subscription = new Subscription();
  pictureSize: boolean = false;
  submitted: boolean = false;
  maxSize: number = 3000;
  constructor(
    private uploadService:UploadService
  ) {}
  ngOnInit(): void {}

  async uploadFileEvt(event: any) {
    const file = event.target.files[0];
    const url=  await this.uploadService.upload(file,'payments-logo');
    console.log({url});
    this.control.patchValue(url);
    // let fileName = file.name;
    // if (file && fileName != null) {
    //   let fileSize = file?.size / 1024;
    //   if (fileSize <= 3000) {
    //     this.submitted = false;
    //     this.pictureSize = false;
    //     let reader = new FileReader();
    //     reader.onload = (e: any): void => {
    //       this.control.setValue(e.target.result);
    //     };
    //     reader.readAsDataURL(file);
    //   } else {
    //     this.control.reset();
    //     this.pictureSize = true;
    //     this.submitted = true;
    //   }
    // }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
