import { Injectable } from '@angular/core';
import { Blog, FAQ, FAQResponse } from '../models/media';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { environment as env } from '../environments/environment';
import { IBlog } from '../models/interfaces/media.model';
@Injectable({
  providedIn: 'root'
})
export class MediaService {
  apiURLBlog = `${env.apiUrl}media/blogs/`;
  apiURLFAQ = `${env.apiUrl}media/faqs/`;

  constructor(private http: HttpClient) { }
  getFAQs(queryParam = ''): Observable<FAQ[]> {
    return this.http.get<FAQ[]>(`${this.apiURLFAQ.slice(0, -1)}${queryParam}`);
  }
  getFAQ(faqId: string): Observable<FAQ> {
    return this.http.get<FAQ>(`${this.apiURLFAQ}${faqId}`);
  }
  createFAQ(Faq: FAQ): Observable<FAQResponse> {
    return this.http.post<FAQResponse>(`${this.apiURLFAQ}`, Faq, { withCredentials: true });
  }
  updateFAQ(Faq: FAQ): Observable<FAQResponse> {
    return this.http.patch<FAQResponse>(`${this.apiURLFAQ}${Faq._id}`, Faq, { withCredentials: true });
  }
  deleteFAQ(id: string): Observable<FAQ> {
    return this.http.delete<FAQ>(`${this.apiURLFAQ}${id}`, { withCredentials: true });
  }
  /*=================================BLOGS==================================== */
  getBlogs(param: {}| ''): Observable<IBlog[]> {
    // const params = new HttpParams({ fromObject: param })
    return this.http.get<IBlog[]>(`${this.apiURLBlog.slice(0, -1)}${param}`).pipe(catchError(err => { throw new Error(err.message) }));
  }
  getBlog(blogId: string): Observable<Blog> {
    return this.http.get<Blog>(`${this.apiURLBlog}${blogId}`);
  }
  createBlog(blog: Blog): Observable<Blog> {
    return this.http.post<Blog>(`${this.apiURLBlog}`, blog, { withCredentials: true });
  }
  updateBlog(blog: Blog): Observable<Blog> {
    return this.http.patch<Blog>(`${this.apiURLBlog}${blog._id}`, blog, { withCredentials: true });
  }
  deleteBlog(id: string): Observable<Blog> {
    return this.http.delete<Blog>(`${this.apiURLBlog}${id}`, { withCredentials: true });
  }
}

